<template>
    <v-btn
        :block="block"
        :class="inline ? null : 'ma-1'"
        @click="onPickFile"
        :height="inline ? 'null' : height"
        :icon="hasIcon && label == null"
        :large="large"
        :loading="loading"
        :rounded="rounded"
        :small="small"
        :title="title">
        <!-- <v-icon v-if="hasIcon && label == null" :large="large" :small="small">{{ icon || leftIcon || rightIcon }}</v-icon>
        <v-row v-else-if="!inline" class="mt-0 mb-3">
            <v-col v-if="leftIcon != null || icon != null" class="mx-0 mx-auto px-auto" cols="12">
                <v-icon :large="large" :small="small">{{ icon || leftIcon || rightIcon }}</v-icon>
            </v-col>
            <v-col v-if="label != null" class="ma-0 pa-0" cols="12">
                <div>{{ label }}</div>
            </v-col>
        </v-row>
        <span v-else>
            <v-icon v-if="leftIcon != null || leftIcon != null" :large="large" :small="small" :left="label != null">{{ icon || leftIcon }}</v-icon>
            {{ label }}
            <v-icon v-if="rightIcon != null" :large="large" :small="small" :right="label != null">{{ rightIcon }}</v-icon>
        </span> -->
        <v-icon v-if="hasIcon && label == null" :large="large" :small="small">{{ icon || leftIcon || rightIcon }}</v-icon>
        <div v-else-if="!inline" class="d-flex flex-column my-1">
            <v-icon v-if="leftIcon != null || icon != null" class="my-1">{{ icon || leftIcon || rightIcon }}</v-icon>
            <div v-if="label != null">{{ label }}</div>
        </div>
        <span v-else>
            <v-icon v-if="leftIcon != null || leftIcon != null" :large="large" :small="small" :left="label != null">{{ icon || leftIcon }}</v-icon>
            {{ label }}
            <v-icon v-if="rightIcon != null" :large="large" :small="small" :right="label != null">{{ rightIcon }}</v-icon>
        </span>
        <input type="file" ref="fileInput" @change="handleFileChange" style="display: none;" />
    </v-btn>
</template>

<script>
    export default {
        name: 'BT-File-Select',
        props: {
            acceptText: {
                type: String,
                default: 'Yes'
            },
            block: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            },
            icon: {
                type: String,
                default: null
            },
            inline: {
                type: Boolean,
                default: false
            },
            label: {
                type: String,
                default: null
            },
            large: {
                type: Boolean,
                default: false
            },
            leftIcon: {
                type: String,
                default: null
            },
            loading: {
                type: Boolean,
                default: false
            },
            rightIcon: {
                type: String,
                default: null
            },
            rounded: {
                type: Boolean,
                default: false
            },
            small: {
                type: Boolean,
                default: false
            },
            title: {
                type: String,
                default: null
            },
            selectToggle: {
                type: Boolean,
                default: false
            }
        },
        watch: {
            selectToggle: function() {
                this.$refs.fileInput.click();
            }
        },
        computed: {
            hasIcon() {
                return this.icon != null || this.leftIcon != null || this.rightIcon != null;
            },
            height() {
                return this.small ? 30 : (this.large ? 80 : 'auto');
            },
        },
        methods: {
            onPickFile() {
                this.$emit('click');
                this.$refs.fileInput.click();
            },
            handleFileChange(e) {                
                this.$emit('input', e.target.files[0]);                
                this.$emit('fileSelected', e.target.files[0]);
            }
        }
    }
</script>

<!-- <template>
    <label class="file-select" :title="title">
        <div class="select-button btn">
            <div>
                <v-icon small :left="text != null">{{ icon }}</v-icon>
                {{ text }}
            </div>
        </div>
        <input type="file" @change="handleFileChange" />
    </label>
</template>

<script>
    export default {
        name: 'BT-File-Select',
        props: {
            value: File,
            title: {
                type: String,
                default: null
            },
            icon: {
                type: String,
                default: 'mdi-file-import-outline'
            },
            text: {
                type: String,
                default: null
            },
            selectToggle: {
                type: Boolean,
                default: false
            }
        },
        watch: {
            selectToggle: function() {
                this.handleFileChange
            }
        },
        methods: {
            handleFileChange(e) {                
                this.$emit('input', e.target.files[0]);                
                this.$emit('fileSelected', e.target.files[0]);
            }
        }
    }
</script>

<style scoped>
    .file-select > .select-button {
        padding: 0px;
    }

    .file-select > input[type="file"] {
        display: none;
    }
</style> -->